import React from "react";
import ataturk from "../../assets/ataturk.jpeg";

const About = () => {
    return (
        <div class="page-text py-5 page_set">
        <img alt="Mustafa Kemal Atatürk" src={ataturk} width={300} style={{display: 'inline-block;', padding: '5px', border: '1px solid rgb(221, 221, 221)', float: 'left', margin: '0px 15px 15px 0px'}} />
       
        <p><strong>SUNUŞ</strong></p>

<p style={{textAlign:'justify'}} >&Ccedil;ağdaş T&uuml;rkiye Cumhuriyeti&rsquo;nin kurucu &ouml;nderi Mustafa Kemal Atat&uuml;rk, binlerce yıllık ge&ccedil;mişe dayanan T&uuml;rk milletinin i&ccedil;inden &ccedil;ıkmış bir liderdir.&nbsp; O, T&uuml;rk toplumuna etki etmiş, y&ouml;n vermiş tarihsel bir şahsiyettir.&nbsp; Bu y&uuml;zden &ccedil;ağdaş T&uuml;rkiye&rsquo;nin siyasal tarihi Mustafa Kemal Atat&uuml;rk ile &ouml;zdeşleşmiştir. &nbsp;Son d&ouml;nem T&uuml;rk siyasal tarihini iyi anlamak, Atat&uuml;rk&rsquo;&uuml;n yaşam s&uuml;recini ve d&ouml;nemini, doğru tespitler ışığında iyi analiz etmekle m&uuml;mk&uuml;nd&uuml;r. Bu nedenle onun yaşam s&uuml;recini ve bu s&uuml;re&ccedil;te yaşanan olayları ve uygulamaları doğru bilgiler etrafında ortaya koymak gerekmektedir. Hızla değişen &ccedil;ok boyutlu ve &ccedil;ok sesli d&uuml;nyada bazı değerleri koruyabilmek ve topluma kavratabilmek i&ccedil;in doğru bilgiye etkin bir şekilde ulaşabilmek &ouml;nemlidir.</p>

<p style={{textAlign:'justify'}} >Bundan dolayı Atat&uuml;rk K&uuml;lt&uuml;r, Dil ve Tarih Y&uuml;ksek Kurumu, Atat&uuml;rk Araştırma Merkezi Başkanlığınca, Gazi Mustafa Kemal Atat&uuml;rk&rsquo;&uuml;n doğumundan &ouml;l&uuml;m&uuml;ne kadar uzanan s&uuml;re&ccedil;te; yaşadığı mek&acirc;nlar, başlattığı, y&ouml;nettiği ya da y&ouml;nlendirdiği, T&uuml;rk ulusunun geleceğinin şekillenmesinde, toplumun yapılanmasında etkili olan olay ve olgular, ger&ccedil;ekleştirdiği inkılap ve d&ouml;nemin tarih&icirc; şahsiyetleri hakkında birincil, g&uuml;venilir kaynaklara dayalı bilgi vermek amacıyla Atat&uuml;rk Ansiklopedisi hazırlanmıştır.</p>

<p style={{textAlign:'justify'}} >Kamuoyundan gelen etkin ve olumlu d&ouml;n&uuml;şler &ccedil;er&ccedil;evesinde 2021 yılı ile birlikte kapsamı 1960&rsquo;lara kadar genişletilen Atat&uuml;rk Ansiklopedisi ile Atat&uuml;rk&rsquo;&uuml;n yaşam s&uuml;recinden başlayarak T&uuml;rkiye Cumhuriyeti tarihine ait olayların, uygulamaların ve kavramların tematik olarak ortaya konulması, yaşadığı d&ouml;neme ilişkin birincil, g&uuml;venilir kaynaklara dayalı bilgiler verilmesi, Atat&uuml;rk konusundaki bilgi ve yorum yanlışlarının giderilmesi, T&uuml;rk ulusunun Atat&uuml;rk&rsquo;&uuml;, inkılaplarını ve Cumhuriyet tarihini algılamasına ve &ouml;z&uuml;msemesine katkı sağlanması hedeflenmiştir.</p>

<p style={{textAlign:'justify'}} >Atat&uuml;rk Ansiklopedisi&rsquo;nde yer alacak konuların belirlenmesinde, bizzat Atat&uuml;rk tarafından kaleme alınan kaynaklara &ouml;ncelik verilmiştir. Bu kaynaklarda s&ouml;z&uuml; edilen mek&acirc;n, olay ve kişilerle ilgili bilgilerin; tarih&icirc; s&uuml;re&ccedil; i&ccedil;inde, konunun uzmanlarınca yazılmış eser ve belgelere dayalı olarak ortaya konulması esas alınmıştır. Ayrıca yerli ve yabancı arşivlerde bulunan her &ccedil;eşit bilgi ve belgelerden faydalanılması &ouml;ng&ouml;r&uuml;lm&uuml;şt&uuml;r.</p>

<p style={{textAlign:'justify'}} >&Ccedil;alışma grubunca; uzmanlık alanları dikkate alınarak tespit edilen yazarlar, madde metinlerini belirlenen ilkelere g&ouml;re kaleme alarak, Kurumumuza teslim etmişlerdir. Hazırlanan madde metinleri, &ccedil;alışma grubu &uuml;yelerinin hakemliğinde incelenerek, bu yazılarda; ifade, &uuml;sl&ucirc;p, y&ouml;ntem ve teknik birliği sağlamak amacıyla gerekli g&ouml;r&uuml;len değişiklik ve d&uuml;zenlemeler yapılarak son şeklini almıştır. Metinler &uuml;zerinde yapılan değişikliklerin oranı hi&ccedil;bir durumda ana metnin 1/4&rsquo;&uuml;n&uuml; ge&ccedil;memiştir.</p>

<p style={{textAlign:'justify'}} >Gelen yazılar, i&ccedil;erik ve yayın ilkelerine uygun bulunmaması durumunda, hakemlik s&uuml;recinde yazarına iade edilmiştir. Maddelerde &ccedil;ok &ouml;nemli ve hacimli d&uuml;zeltme ve değişiklik &ouml;nerilmesi halinde metinler, yazarlarına tekrar g&ouml;zden ge&ccedil;irilmek &uuml;zere g&ouml;nderilmiştir. Kullanılan kaynaklar, yaygın bilimsel y&ouml;ntemlere g&ouml;re metin sonunda g&ouml;sterilmiştir. Yazarlar, metinlerinde yer almasını uygun g&ouml;rd&uuml;kleri resim, grafik, harita vb. g&ouml;rsel malzemeyi yazılarına eklemişlerdir.</p>

<p style={{textAlign:'justify'}} >Madde yazımını kabul eden yazarlardan, metinlerini &ouml;zg&uuml;n ve g&uuml;venilir kaynaklara dayalı olarak ve en doğru tarzda tanıtacak nitelikte ve anlaşılır bir dille ele almaları istenmiştir. Her maddenin i&ccedil;eriğine uygun bir hacimde olmasına dikkat edilmiştir. Maddeler yazarlar tarafından kaleme alınırken konunun Atat&uuml;rk&rsquo;le ilgisinin kurulmasına &ouml;zen g&ouml;sterilmiştir.&nbsp;</p>

<p style={{textAlign:'justify'}} >29 Ekim 2020 tarihi itibariyle dijital ortamda erişime a&ccedil;ılmış olan Atat&uuml;rk Ansiklopedisi&rsquo;nde şu anda 1500&rsquo;e yakın madde başlığı yer almaktadır. Bu maddelerin 1200&rsquo;e yakın kısmı erişime a&ccedil;ılmış olup kalan maddelerin yayımlanması da s&uuml;re&ccedil; i&ccedil;erisinde tamamlanacaktır. Atat&uuml;rk Ansiklopedisi&rsquo;nin dijital ortamda yayımlanmış olması, geniş kitlelere erişiminin yanı sıra onun s&uuml;rekli g&uuml;ncellenmesini, yeni bilgi ve bulgular doğrultusunda ortaya &ccedil;ıkan istek, &ouml;neri ve eleştirilerin dikkate alınmasını sağlayacaktır.</p>

<p style={{textAlign:'justify'}} >Cumhuriyet&rsquo;in ilanının 97.yılında Gazi Mustafa Kemal Atat&uuml;rk&rsquo;e minnet ve ş&uuml;kranlarımızın bir nişanesi olarak yayına başlayan bu nadide eserin ortaya &ccedil;ıkmasında g&ouml;rev alan Ansiklopedi maddelerinin yazarlarına, inceleyicilerine, Atat&uuml;rk Ansiklopedisi &Ccedil;alışma Grubu &uuml;yelerine, ATAM&rsquo;ın &ouml;nceki idarecilerine, Y&uuml;ksek Kurum uzmanlarına, personeline ve emeği ge&ccedil;enlere teşekk&uuml;r ederiz.</p>

<p style={{textAlign:'justify'}} >Detaylı bilgi ve erişim i&ccedil;in&nbsp;<a href="https://ataturkansiklopedisi.gov.tr" style={{ color:"blue", textDecoration:"underline" }}>https://ataturkansiklopedisi.gov.tr/&nbsp;</a>adresini ziyaret edebilirsiniz.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<h3 style={{ textAlign:'right'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ATAT&Uuml;RK ARAŞTIRMA MERKEZİ BAŞKANLIĞI</h3>

      </div>
    )
}
export default About