import axios from 'axios';
import config from '../config';
console.log("asdada ",config.project , config.project.id)
export const getProjectCategory = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/category`;
    return await axios.get(url)
    .then(response => response.data)
}

export const getProjectApi = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/project/byId/`;
    return await axios.get(url)
    .then(response => response.data)
}

export const getOtherDictionary = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/otherDictionary/`;
    return await axios.get(url)
    .then(response => response.data)
}

export const getItemTitleList = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/title/`;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemSliderList = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/slider/`;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemList = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/`;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemDailyWeeklyMostView = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/dailyAndWeeklyAndMostView/`;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemListByUserId = async(userId)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/byUserId/`+userId;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemListSearch = async(searchString,searchConfig)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/search/`;
    return await axios.post(url,{searchString , searchConfig})
    .then(response => response.data)
}
export const getItemGroupSearch = async(searchString)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/searchGroup/`;
    return await axios.post(url,{searchString })
    .then(response => response.data)
}
export const putUpdateItem = async(item)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/`;
    return await axios.put(url,item)
    .then(response => response.data)
}
export const postCommentItem = async(object)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/addComment`;
    return await axios.post(url,object)
    .then(response => response.data)
}
export const getItemById = async(id)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/byId/`+id;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemByCategoryId = async(id)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/categoryId/`+id;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemByOldTitle = async(oldTitle)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/oldTitle/`+oldTitle;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemImagesByItemId = async(id)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/itemImages/`+id;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemListSize = async(size)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/size/${size}`;
    return await axios.get(url)
    .then(response => response.data)
}

export const getItemLangByItemId = async(itemId)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/itemLang/${itemId}`;
    return await axios.get(url)
    .then(response => response.data)
}

export const getWriterById = async(id)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/writers/`+id;
    return await axios.get(url)
    .then(response => response.data)
}

export const getWriters = async()=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/writers/all`;
    return await axios.get(url)
    .then(response => response.data)
}
export const getItemListByIdList = async(idList)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/list/`+idList;
    return await axios.get(url)
    .then(response => response.data)
}



export const getItemPublicWORDByIdApi = async (id,lang,langId)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/word/byId/${id}?lang=${lang}&langId=${langId}`;
    return await axios.get(url,{
       method: 'GET',
       responseType: 'blob',
    }) 
    .then(response => {
       const file = new Blob(
          [response.data],  
          {type: 'application/docx'});
          return file;
    }).catch(error=>{
      
        return {status :false , message: "Sunucu hatası"}
    })
    
    ;
 }
 
export const getItemPublicPDFByIdApi = async (id,lang,langId)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/pdf/byId/${id}?lang=${lang}&langId=${langId}`;
    return await axios.get(url,{
       method: 'GET',
       responseType: 'blob',
    }) 
    .then(response => {
       const file = new Blob(
          [response.data],  
          {type: 'application/pdf'});
          return file;
    }).catch(error=>{
      
        return {status :false , message: "Sunucu hatası"}
    })
    
    ;
 }

 export const putProjectViewCount = async(item)=>{
    const url = `${config.apiUrl}/v1/${config.project.id}/itemPublic/viewCount`;
    return await axios.put(url,item)
    .then(response => response.data)
}