import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import ReactToPrint from "react-to-print";
import { getItemById, getItemByOldTitle, getItemImagesByItemId, getItemLangByItemId, getItemListSize, getItemPublicPDFByIdApi, getItemPublicWORDByIdApi, postCommentItem, putUpdateItem } from "../api";
import avatar from "../assets/1.jpg";
import Icons from "../assets/Icons";
import Fancybox from "../components/Fancybox";
import ReleatedCard from "../components/ReleatedCard";
import config, { selectedProject } from "../config";
import { capitalizeTurkish, getDateLocale, getItemPrimaryImage, getItemUrl } from "../utils/utilFunction";

const popover = (item)=> (
  <Popover id="popover-basic">
    <Popover.Header as="h3">{item.title}</Popover.Header>
    <Popover.Body>
      <div className="textarea">
        <p>
          <span>Yazar:</span> {item.writerName}
        </p>
        <p>
          <span>Web Sitesi:</span> {config.appUrl}
        </p>
        <p>
          <span>Yayımcı:</span> {config.project.name}
        </p>
        <p>
          <span>Erişim Tarihi:</span> {new Date().toLocaleDateString()}
        </p>
        <p>
          <span>Web Sitesi Adresi:</span>{" "}
          {getItemUrl(item)}
        </p>
      </div>
      <p><b>Kopyalama Metni</b></p>
      <p id="atifCopyText">
        {`${item.writerName}, "${item.title}", ${config.project.name},
        ${getItemUrl(item)}
        (${new Date().toLocaleDateString()})`}.
      </p>
    </Popover.Body>
  </Popover>
);

function MyVerticallyCenteredModal(props) {
  const {item} = props;

  const [nameSurname, setNameSurname] = useState("");
	const [mail, setMail] = useState("");
	const [comment, setComment] = useState("");
  const [phone, setPhone] = useState("");
  const saveComment = async()=>{

		const data = { 
			nameSurname,
			mail ,
      phone,
			comment,
			itemId : item.id
		}

		if(nameSurname.length>0 && mail.length>0 && comment.length>1)
		{
			const res = await postCommentItem(data);
			if(res.status)
			{
				alert("mesaj gönderme başarılı");
        props.onHide();
	
			}
	
		}
		else
		{

			alert("Mesaj Gönderme başarısız" )
		}

	


	}

  useEffect(() => {
    window.scrollTo(0, 0); // Sayfa başına scroll yap
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedProject.name} Madde Mesaj Formu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          Bu form aracılığıyla, sadece <strong>{item.title} </strong> 
          maddesi ile alakalı mesajların iletilmesi rica olunur. Atatürk
          Ansiklopedisi hakkındaki diğer hususların
          <a href="/iletisim">İletişim Formu</a> aracılığıyla iletilmesi rica
          olunur.
        </Alert>

        <Form method="POST">
          <Form.Group className="mb-3">
            <Form.Label>Mesajınız*</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Ayrıntılı olarak mesajınızı yazınız."
              rows={3}
              onChange={e=>setComment(e.target.value)}  value={comment}
            />
          </Form.Group>
          <p>İletişim Bilgileriniz</p>
          <Form.Group className="mb-3">
            <Form.Label>Adınız ve Soyadınız*</Form.Label>
            <Form.Control type="text"  onChange={e=>setNameSurname(e.target.value)} value={nameSurname} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>E-posta Adresiniz*</Form.Label>
            <Form.Control type="email" onChange={e=>setMail(e.target.value)}  value={mail} placeholder="isim@ornek.com" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Telefon Numaranız</Form.Label>
            <Form.Control type="number" onChange={e=>setPhone(e.target.value)}  value={phone} placeholder="05XXXXXXXXX" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">
          Kapat
        </Button>
        <Button onClick={()=>saveComment()} variant="danger" className="text-white">
          Gönder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Detail = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const componentRef = React.useRef(null);
  const navigate = useNavigate()

  const [item, setItem] = useState({});
  const [relationItemLoading, setRelationItemLoading] = useState(false);
	const [relationItemList, setRelationItemList] = useState([]);
const [relationStatus, setRelationStatus] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [langItemList, setLangItemList] = useState([]);
  const [langList, setLangList] = useState([]);


  const [customizerStatus, setCustomizerStatus] = useState(true);
  const [customSelectedAlign, setCustomSelectedAlign] = useState("justify");
  const [customFontSize, setCustomFontSize] = useState(15);
  const [customBackgroundColor, setCustomBackgroundColor] = useState("#fff");

  const params = useParams()
const {id,oldTitle} = params; 
  
  const getAPI = async()=>{
    console.log('params ', params )
    const res = id !== undefined ? await getItemById(id) : await getItemByOldTitle(oldTitle);
     if(res.status){
      setItem(res.data);
      const tempItem = res.data;
      setRelationItemLoading(true);
      setRelationStatus(tempItem.relationItems !=="")
      const res3 =  await getItemImagesByItemId(tempItem.id);

      if(res3.status)
      setImageList(res3.data);

      console.log('tempItem', tempItem)
      if(tempItem.relationItems ==="")
      {
        setRelationItemLoading(true);
        const res1 = await  getItemListSize(8) ;
        setRelationItemList(res1.data);
        setRelationItemLoading(false);
        setRelationStatus(false)
      }
      else{
        setRelationItemList(JSON.parse(tempItem.relationItems));
        setRelationStatus(true)
        setRelationItemLoading(false);
      }

      if(tempItem.lang !=="" && tempItem.isMultiLang===1)
        {

          const res1 = await  getItemLangByItemId(tempItem.id) ;

     
          if(tempItem.lang==="Türkçe")
          {
            setLangList(["Türkçe" , ...res1.data.map(x=>x.lang)]);
            setLangItemList([{lang:"Türkçe", langItemText : tempItem.itemText}, ...res1.data])
          }else
          {
            let tr = res1.data.find(x=>x.lang === "Türkçe");
            setLangList(["Türkçe",tempItem.lang, ...res1.data.filter(x=>x.lang !== "Türkçe").map(x=>x.lang)]);
            setLangItemList([tr , {lang:tempItem.lang, langItemText : tempItem.itemText}, ...res1.data.filter(x=>x.lang !== "Türkçe")])
          }

         // setLangItemList(tlist);
      
         

        }
      
      if(tempItem)
      {
        await updateViewCountApi(tempItem) 
      }


        }


        	



    }


    const updateViewCountApi = async (tempItem)=>{
      await putUpdateItem({id:tempItem.id , viewCount : tempItem.viewCount+1})
    }

    

    useEffect(() => {
      console.log('id ', id )
      if(id!== undefined)
      getAPI();
    }, [id])
   
    useEffect(() => {
      console.log('oldTitle ', oldTitle )
      if(oldTitle!== undefined)
      getAPI();
    }, [oldTitle])


    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
    }, [componentRef.current]);

    const onClickDownload = async(type,lang="tr")=>{

      const selectedLang = { id:1 , langTitle:"Türkçe" }
      if(type==="PDF")
      {
        const pdfFile = await getItemPublicPDFByIdApi(item.id , lang,selectedLang.id);
  
        const fileUrl = URL.createObjectURL(pdfFile);
        console.log("item ",item)
        
         let a = document.createElement('a');
              a.href = fileUrl;
              a.target="_blank";
              a.download =  (lang === "tr" ?  item.title : selectedLang.langTitle) +'.pdf';
              a.click();
      }
      if(type==="WORD")
      {
        const pdfFile = await getItemPublicWORDByIdApi(item.id , lang,selectedLang.id);
  
        const fileUrl = URL.createObjectURL(pdfFile);
        console.log("item ",item)
        
         let a = document.createElement('a');
              a.href = fileUrl;
              a.target="_blank";
              a.download =  (lang === "tr" ?  item.title : selectedLang.langTitle) +'.docx';
              a.click();
  
      }
      if(type==="PRINT")
      {
    
        window.print()
      }
   
    }
    
  return (
    <Container>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        item={item}
      />
   
      <div className="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">
          {item.title}
          <small>
           {item.definition}
          </small>
        </h1>
      </div>
      <div className="page-text py-5 page_set position-relative">

    
      {customizerStatus && <div className="customizer" id="customizerArea">
      <div className="box">
        <div className="top">
          <span id="customizerTextLeft" onClick={()=>setCustomSelectedAlign("left")}>
            <i className="pi pi-align-left" style={{ fontSize: '1rem' }}></i>
          </span>
          <span id="customizerTextJustify" onClick={()=>setCustomSelectedAlign("justify")}>
            <i className="pi pi-align-justify" style={{ fontSize: '1rem' }}></i>
          </span>
        </div>
        <div className="bottom">
          <span id="customizerHighText" onClick={()=>setCustomFontSize(customFontSize-1)}>        <i
                          dangerouslySetInnerHTML={{ __html: Icons.fonstSizeDown }}
                          className="_icon"
                        /></span> <span id="customizerLowText" onClick={()=>setCustomFontSize(customFontSize+1)}>        <i
                        dangerouslySetInnerHTML={{ __html: Icons.fontSizeUp }}
                        className="_icon"
                      /></span>
        </div>
        <div className="colors">
          <span id="customizerWhiteBG" onClick={()=>setCustomBackgroundColor("#fff")}></span> <span id="customizerReserveBG" onClick={()=>setCustomBackgroundColor("#faebd7")}></span>
        </div>
        {langList.map(x=> <div key={x} className="close-customizer" style={{fontSize:9}} id="closeCustomizer">
         <a href={"#sections"+x.trim()} >{x}</a> 
        </div>)}
       
        
        <div className="close-customizer" id="closeCustomizer" onClick={()=>setCustomizerStatus(false)}>
          [Kapat]
        </div>
      </div>
    </div>}
 


        <Container ref={componentRef}>
          <Row>
            <Col className="d-block d-md-none">
            <div className="item-image">
                <a href={avatar} className="text-decoration-none">
                  <Image src={getItemPrimaryImage(item.primaryImage)} alt={item.title} />
                  <div className="caption">{item.title}</div>
                </a>
              </div>
            </Col>
            <Col lg={9}>
              <div className="_content_wrapper">
                <div className="page-buttons mb-4" id="pageButtons">
                  <div className="top">
                    <div className="left">
                      <div>
                      <strong>{item?.writerList &&  item?.writerList.length> 1 ? "Yazarlar: ": "Yazar: "}</strong>{" "}
                        {
                         item?.writerList && item?.writerList.map((x,index)=>[<a key={x.id} href={"/yazar-detay/"+x.id+"/"+x.name}>{capitalizeTurkish(x.name)}</a>, index + 1  < item.writerList.length ? ", " : ""])
                        }
                        {/* <a href="yazar-detay.php">Süleyman HATİPOĞLU</a>,{" "}
                        <a href="yazar-detay.php">Hülya Toker</a> */}
                      </div>
                    </div>
                    <div className="right">
                      <span title="PDF Dosyası İndir"         className="icon-container" onClick={()=>onClickDownload("PDF","tr")}>
                        <i
                          dangerouslySetInnerHTML={{ __html: Icons.pdf }}
                          className="_icon"
                        />
                      </span>
           
                      <ReactToPrint
          trigger={() => {
        
            return <span title="Yazdır" className="icon-container">
            <i
              dangerouslySetInnerHTML={{ __html: Icons.print }}
              className="_icon"
            />
          </span>;
          }}
          content={reactToPrintContent}
        />

                      <span title="Atıf Bilgileri"         className="icon-container">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={popover(item)}
                          rootClose={true}
                          className="icon-container"
                        >
                          <i
                            dangerouslySetInnerHTML={{
                              __html: Icons.blockquate,
                            }}
                            className="_icon"
                          />
                        </OverlayTrigger>
                      </span>
                      <span
                        title="Bu madde hakkında bildirimde bulunun"
                        onClick={() => setModalShow(true)}
                        className="icon-container"
                      >
                        <i
                          dangerouslySetInnerHTML={{ __html: Icons.mail }}
                          className="_icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <p className="publish-date">
                  <small className="text-secondary">
                    Yayınlanma Tarihi: {getDateLocale(item.releaseDate)}
                  </small>
                </p>
             
               {langItemList.length === 0 && <div id="sections" style={{fontSize:customFontSize , backgroundColor : customBackgroundColor, textAlign:`${customSelectedAlign} !important` }}>

                  <div   dangerouslySetInnerHTML={{ __html: item.itemText }}  style={{fontSize:customFontSize , backgroundColor : customBackgroundColor, textAlign:`${customSelectedAlign} !important` }} ></div>
                
                </div>}
                {
                  langItemList.map((langItem,index)=>
                  <div key={"abc"+index} id={"sections"+langItem.lang.trim()} style={{fontSize:customFontSize , backgroundColor : customBackgroundColor, textAlign:`${customSelectedAlign} !important` }}>
                    <h4 className="my-5">{langItem.lang} - {item.title}</h4>
                  <div   dangerouslySetInnerHTML={{ __html: langItem.langItemText }}  ></div>
                
                </div>)
                }
                <hr />
                <div className="social_links">
                  <FacebookShareButton className="m-2"  title={item.title} hashtag={"#"+String(item.keyword).replace(",","#")} url={getItemUrl(item)}>
                    <div
                      dangerouslySetInnerHTML={{ __html: Icons.facebook }}
                      className="_icon"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton className="m-2"  hashtags={String(item.keyword).trim().split(",").map(x=>x.trim())} url={getItemUrl(item)}>
                    <div
                      dangerouslySetInnerHTML={{ __html: Icons.x }} 
                      className="_icon"
                    />
                  </TwitterShareButton>
                  <InstapaperShareButton className="m-2" hashtag={"#"+String(item.keyword).replace(",","#")}  url={getItemUrl(item)}>
                    <div
                      dangerouslySetInnerHTML={{ __html: Icons.instagram }}
                      className="_icon"
                     
                      
                    />
                  </InstapaperShareButton>
                  <WhatsappShareButton
          hashtag={"#"+String(item.keyword).replace(",","#")}  url={getItemUrl(item)}
          quote={item.title}
         
        >
     <div 
                      dangerouslySetInnerHTML={{ __html: Icons.whatsApp }}
                      className="_icon"
                    />
        </WhatsappShareButton>

                </div>
                <hr />
                {item.keyword &&<h4>Anahtar Kelimeler</h4>}

<div   className="references">
  {item.keyword && item.keyword.split(",").map(keyword=><a href={`/arama/${encodeURIComponent(keyword)}`} className="m-2">#{String(keyword).trimLeft()}</a>)}
</div>



                <hr />
                <h4>Kaynakça</h4>

                <div   className="references" dangerouslySetInnerHTML={{ __html: item.sourceText }} ></div>
               
                <hr />
                <p className="text-center">
                  <span>
                    <svg
                      aria-hidden="true"
                      className="svg-inline--fa fa-chart-bar"
                      data-fa-i2svg=""
                      data-icon="chart-bar"
                      data-prefix="fas"
                      role="img"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zm96 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-192 0c-17.7 0-32-14.3-32-32zm32 64H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H160c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H160c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>{" "}
                  Görüntülenme Sayısı: <strong>{item.viewCount}</strong>
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="item-image d-none d-md-block">
              <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <a data-fancybox="gallery" href={getItemPrimaryImage(item.primaryImage)}  data-caption={item.title}>
                  <Image src={getItemPrimaryImage(item.primaryImage)} alt={item.title} width={"100%"}/>

                  {/* <div className="caption">{item.title}</div> */}
                </a>
                </Fancybox>
              </div>

              <div className="related-items">
                <h3>{relationStatus ? "İlişkili Maddeler" : "Diğer Maddeler"}</h3>
                <div className="boxes">
                  {relationItemList.map(( item, i) => (
                      <ReleatedCard key={i} item={item} loading={relationItemLoading} />
                    ))}
                  <a href="#" onClick={()=>navigate('/iliskili-maddeler', { state :  { item , relationItemList }  })}  >
                  {relationStatus ? "Bütün ilişkili maddeler" : "Bütün Maddeler"} »
                  </a>
                </div>
              </div> 

              <div className="item-gallery">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                 

                  {
                    imageList.map((img,index)=> 
                    <div className="box" key={index}>
                    <div className="image">
                      <a data-fancybox="gallery" href={img.url} data-caption={img.docTypeTitle}>
                        <Image src={img.url} alt={img.docTypeTitle} width={"100%"}/>
                      </a>
                      <div className="caption">{img.docTypeTitle} </div>
                    </div>
                  </div>)
                  }
                </Fancybox>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </Container>
  );
}; 
export default Detail;
