import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route }from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home'; 
import Items from './pages/Items';
import Page from './pages/Page';
import Authors from './pages/Authors';
import Contact from './pages/Contact';
import Detail from './pages/Detail';
import ReleatedItems from './pages/ReleatedItems';
import CategoryDetail from './pages/CategoryDetail';
import AuthorDetail from './pages/AuthorDetail';
// import NotFound from './pages/NotFound'; // 404 sayfası
import Search from './pages/Search';

const App = () => {

 

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/maddeler/:catId?" element={<Items/>} />
          <Route path="/yazarlar" element={<Authors/>} />
          <Route path="/yazar-detay/:id/:name?" element={<AuthorDetail/>} />
          <Route path="/iletisim" element={<Contact/>} />
          <Route path="/iliskili-maddeler" element={<ReleatedItems/>} />
          <Route path="/kategori-detay" element={<CategoryDetail/>} />
          <Route path="/kategori-detay/:id/:name?" element={<CategoryDetail/>} />
          <Route path="/arama" element={<Search />} />
          <Route path="/arama/:searchText" element={<Search />} />
          <Route path="/detay" element={<Detail/>} />
          <Route path="/detay/:id/:name*" element={<Detail/>} />
          <Route path="/hakkinda/:slug" element={<Page />} />
          <Route path="/:slug" element={<Page />} />
          {/* 404 sayfası için bir Route */}
          {/* <Route component={NotFound} /> */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
