const WorkGroup = () => {
    return (
       
        <div class="page-content relative">
         <p>1. Prof. Dr. Hakan UZUN / Başkan</p>
            <p>2. Prof. Dr. Zehra ASLAN</p>
            <p>3. Prof. Dr. Erdal AYDOĞAN</p>
            <p>4. Prof. Dr. Mehmet Serhat YILMAZ</p>
            <p>5. Prof. Dr. Nurten ÇETİN</p>
            <p>6. Prof. Dr. Tahir KODAL</p>
            <p>7. Prof. Dr. Hacer TOPAKTAŞ</p>
            <p>8. Doç. Dr. Esra ÖZSÜER</p>
            <p>9. Doç. Dr. Orhan AVCI</p>
            <p>10. Doç. Dr. Hatice GÜZEL MUMYAKMAZ</p>
            <p>11. Doç. Dr. Ramazan Erhan GÜLLÜ</p>
            <p>12. Dr. İbrahim BAŞ</p>


 

            <p>&nbsp;</p>
            <h3>PROJEDEN SORUMLU UZMANLAR</h3>
            <p>Selcan KOÇASLAN- Yüksek Kurum Uzmanı</p>
            <p>Aslı AKDOĞANBULUT İNSAN – Yüksek Kurum Uzmanı</p>
            <p>Nurdoğan AKDAĞ – Bilişim Uzmanı</p>

        </div>
        
    )
}
export default WorkGroup