import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getItemListSize, getProjectApi, putProjectViewCount } from "../api";
import config from "../config";
import UserCard from "./UserCard";
import UserCardWrapper from "./UserCardWrapper";

const HomeSection = () => {

    const [data1, setData1] = useState([]);

    const [project, setProject] = useState({});

    const getAPI = async()=>{


	const res1 =  getProjectApi(config.project.id);	
	const res3 =  getItemListSize(6);

	Promise.all([res1,res3]).then((sonuclar)=>{
		console.log("sonuclar ",sonuclar);
        setData1(sonuclar[1].data);
        setProject(sonuclar[0].data); 

	})
    await putProjectViewCount({})

    }
    useEffect(() => {
      getAPI();
    }, [])




    return (
        <div className="home-sections">
            <Container>
                <div className="d-flex justify-content-between">
                    <h2 className="title _set">Ansiklopedide Yeni</h2>
                    <Link className="seeAll" to="/maddeler">Tümü</Link>
                </div>
                <UserCardWrapper>
                <Row>
                        {data1.map((item, i) => (
                              <Col key={i} xs={6} className="px-3 px-md-5">
                                <UserCard info={item} />
                              </Col>
                            ))}
                    </Row>
                </UserCardWrapper>
                        

                <div className="view_count">Görüntülenme Sayısı: {project.viewCount}</div>


            </Container>
        </div>
    )
}
export default HomeSection