const Contributors = () => {
    return (
       
        <table className="table table-striped">
		<thead>
			<tr role="row">
				<th>AD-SOYAD</th>
			</tr>
		</thead>
		<tbody>
		<tr>
    <td class="column-1">Prof. Dr. Abdullah İLGAZİ</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Adnan SOFUOĞLU</td>
</tr>
<tr>
    <td class="column-1">Doç. Dr. Ahmet KILINÇ</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Cemalettin TAŞKIRAN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Cezmi ERASLAN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Dilşen İNCE ERDOĞAN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Esat ARSLAN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Hale ŞIVGIN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Halil BAL</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Hamit PEHLİVANLI</td>
</tr>
<tr>
    <td class="column-1">Dr. Hüseyin AĞCA</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. İhsan GÜNEŞ</td>
</tr>
<tr>
    <td class="column-1">Dr. İsmet GÖRGÜLÜ</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. İzzet ÖZTOPRAK</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Kemal ÇELİK</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Mehmet Akif TURAL</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Mehmet TEMEL</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Mehmet Ali BEYHAN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Mithat AYDIN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Mustafa Sıtkı BİLGİN</td>
</tr>
<tr>
    <td class="column-1">Nâsır YÜCEER</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Neşe ÖZDEN</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Nizami CAFEROV</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Osman HORATA</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Oğuz AYTEPE</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Ömer Osman UMAR</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Sadık TURAL</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Selami KILIÇ</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Selma YEL</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Süleyman BEYOĞLU</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Utkan KOCATÜRK</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Yasemin DOĞANER</td>
</tr>
<tr>
    <td class="column-1">Prof. Dr. Yüksel ÖZGEN</td>
</tr>
<tr>
    <td class="column-1">Merve UĞUR</td>
</tr>
<tr>
    <td class="column-1">Orhan İSTANBUL</td>
</tr>
<tr>
    <td class="column-1">Erdem Ünlen</td>
</tr>

		</tbody>
	</table>
        


    )
}
export default Contributors