import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getWriters } from "../api";
import { capitalizeTurkish, getFullName, getFullNameCapitalize } from "../utils/utilFunction";

const Authors = () => {
  const alphabet = 'ABCÇDEFGHIİJKLMNOÖPRSTUÜVWXYZ'.split('');


  const [data,setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedChar, setSelectedChar] = useState('');

  const getAPI = async()=>{
  const resSlider = await getWriters();
   if(resSlider.status){

    
    setData( resSlider.data);
    setFilteredData(resSlider.data);
      }
  }
  useEffect(() => {
    getAPI();
  }, [])


  useEffect(() => {
   console.log('selectedChar ', selectedChar )

   setFilteredData(selectedChar !== "" ? data.filter(x=>x.name.toUpperCase().startsWith(selectedChar)) : data)

  }, [selectedChar])  


  return (
    <Container>
      <div className="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">Yazarlar</h1>
      </div>
      <div className="items-filter pt-3">
      <ul id="items-filter-area">
        <li  className={selectedChar === "" ? "btn-info active" : ""} data-filter="all" style={{ minWidth: "50px" }}  onClick={()=>setSelectedChar("")}>
          <span>Tümü</span>
        </li>
        {alphabet.map((letterOrNumber, index) => (
          <li className={selectedChar === letterOrNumber ? "btn-info active" : ""} key={index}  data-filter={letterOrNumber} onClick={()=>setSelectedChar(letterOrNumber)}>
            <span>{letterOrNumber}</span>
          </li>
        ))}
      </ul>
      </div>
      <div className="filter_result">
        <ul id="categorylist" className="category-list">
          {
            filteredData.map((writer,index)=>
            <li key={"writer"+index}>
            <a href={"/yazar-detay/"+writer.id+"/"+ getFullName(writer)}>{ getFullNameCapitalize(writer)} ({writer.itemCount})</a>
          </li>
            )
          }
          
        </ul>
      </div>
    </Container>
  );
};

export default Authors;
