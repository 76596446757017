import React, { useEffect, useState } from "react";
import {
  Alert,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getItemGroupSearch } from "../api";
import { getItemPath } from "../utils/utilFunction";
import UserCard from "../components/UserCard";
import UserCardWrapper from "../components/UserCardWrapper";

const Search = () => {

	const params = useParams()
	const {searchText} = params; 


  const [titleData, setTitleData] = useState([]);
  const [writerData, setWriterData] = useState([]);
  const [contentData, setContentData] = useState([]);

  
  const getAPI = async(value)=>{
    const result = await getItemGroupSearch(value);

      const {titleData,writerData ,contentData } = result.data;
      setContentData(contentData);
      setTitleData(titleData);
      setWriterData(writerData);

     
  
  }
  
  useEffect(() => {
    getAPI(searchText);
  }, [searchText])

  const navigate = useNavigate();
  const clearData = ()=>{
    setTitleData([]);
    setContentData([]);
    setWriterData([]);

  }
console.log('---- ', titleData.length >0 ? "tab1" : contentData.length > 0 ? "tab2" : "tab3" )
console.log('---- ', titleData.length, contentData.length , writerData.length )

  return (
    <Container>
      <div className="page-text pt-5 mb-0 page_set mb-2">
        <h1 className="page_title">Arama Sonuçları</h1>
      </div>

      <div className="filter_result">

 { (titleData.length >0 || contentData.length >0 || writerData.length>0) ?
                  <Tabs defaultActiveKey={titleData.length >0 ? "tab1" : contentData.length > 0 ? "tab2" : "tab3"} fill>
                   {titleData.length >0 && <Tab eventKey="tab1" title={`Başlık (${titleData.length})`} disabled={titleData.length===0}>
                   <UserCardWrapper>
                   <Row style={{marginTop:40}}>
                   {
                          titleData.map((item,index)=>    <UserCard info={item} /> )}</Row></UserCardWrapper>
                      {/* <ListGroup>
                        {
                          titleData.map((item,index)=>    <ListGroup.Item action key={"title"+index} onClick={()=>{navigate(getItemPath(item)); clearData(); }}>
                          <strong className="d-block">
                            {item.title}
                          </strong>
                          <p>{item.definition}</p>
                        </ListGroup.Item>)
                        }
   
                      </ListGroup> */}
                    </Tab>}
                    {contentData.length >0 &&<Tab eventKey="tab2" title={`İçerik (${contentData.length})`} >
                      
                    <ListGroup>
                    {
                          contentData.map((item,index)=>    <ListGroup.Item action key={"title"+index}  onClick={()=>{navigate(getItemPath(item)); clearData(); }}>
                          <strong className="d-block">
                            {item.title}
                          </strong>
                          <p>{item.definition}</p>
                        </ListGroup.Item>)
                        }

                      </ListGroup>
                    </Tab>}
                    {writerData.length >0 &&<Tab eventKey="tab3" title={`Yazar (${writerData.length})`}>
                      
                    <ListGroup>
                    {
                          writerData.map((item,index)=>    <ListGroup.Item action key={"title"+index} onClick={()=>{navigate(`/yazar-detay/${item.userId}/${item.writerName}`); clearData(); }} >
                          <strong className="d-block">
                            {item.writerName}
                          </strong>
                        </ListGroup.Item>)
                        }

                      </ListGroup>
                    </Tab>}
                  </Tabs>:
                  <Alert>
                    Herhangi bir sonuç bulunamadı!
                  </Alert>
                  }
          
      </div>
    </Container>
  );
};

export default Search;
