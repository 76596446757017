

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  FormControl,
  Image,
  InputGroup,
  ListGroup,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getItemGroupSearch } from "../api";
import Icons from "../assets/Icons";
import atam from "../assets/atam.png";
//import logo from "../assets/logo.svg";
import logo from "../assets/logo.png";
import yk from "../assets/ayk-logo.png";

import says from "../assets/says.png";
import { getItemPath } from "../utils/utilFunction";
import { selectedProject } from "../config";

const Header = () => {
  const navigate = useNavigate();
  const [isShow, setIsShow] = React.useState(false)
const [searchText, setSearchText] = useState("");

const [titleData, setTitleData] = useState([]);
const [writerData, setWriterData] = useState([]);
const [contentData, setContentData] = useState([]);

const resultsRef = useRef(null);



useEffect(() => {

  const handleClickOutside = (event) => {
    if (resultsRef.current && !resultsRef.current.contains(event.target)) {
      setIsShow(false)
    }
  };

  
  if (isShow) {
    document.addEventListener('mousedown', handleClickOutside);
  } else {
    document.removeEventListener('mousedown', handleClickOutside);
  }

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [resultsRef,isShow]);



  const onChangeSearch = async(value)=>{

    setSearchText(value);

    if(value.length > 2){ 
      const result = await getItemGroupSearch(value);

      const {titleData,writerData ,contentData } = result.data;
      setContentData(contentData);
      setTitleData(titleData);
      setWriterData(writerData);
  

      if(contentData.length > 0 || writerData.length >0 || titleData.length>0)
      setIsShow(true)
    }

  }
  const clearData = ()=>{
    setTitleData([]);
    setContentData([]);
    setWriterData([]);
    setIsShow(false);
    setSearchText("");
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Enter tuşuna basıldığında yönlendirme yap
      setIsShow(false)
      navigate(`/arama/${encodeURIComponent(searchText)}`);
    }
  };

  return (
    <header>
      <Container>
        <Row className="justify-content-between">
          <Col xs={12} md={4}>
            <Stack direction="horizontal" gap={3} className="align-items-center d-flex mb-3 mb-md-0">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <div
                    dangerouslySetInnerHTML={{ __html: Icons.menu }}
                    style={{
                      width: "20px",
                      display: "inline-block",
                    }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/">
                    Anasayfa
                  </Dropdown.Item>
                  {/* <Dropdown as={ButtonGroup}>
                    <Dropdown.Item as={Link} to="/hakkinda">
                      Hakkında
                    </Dropdown.Item>
                    <Dropdown.Toggle variant="" id="dropdown-basic2" split />
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/hakkinda/kunye">
                        Künye
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/hakkinda/calisma-grubu">
                        Çalışma Grubu
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/hakkinda/emegi-gecenler">
                        Emeği Geçenler
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/hakkinda/kılavuz">
                        Kılavuz
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <Dropdown.Item as={Link} to="/hakkinda">
                      Hakkında
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/hakkinda/kunye">
                        Künye
                      </Dropdown.Item> 
                  <Dropdown.Item as={Link} to="/hakkinda/calisma-grubu">
                        Çalışma Grubu
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/hakkinda/emegi-gecenler">
                        Emeği Geçenler
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/hakkinda/kılavuz">
                        Kılavuz
                      </Dropdown.Item>


                  <Dropdown.Item as={Link} to="/maddeler">
                    Maddeler
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/yazarlar">
                    Yazarlar
                  </Dropdown.Item>
                  <a href="https://giris.ayk.gov.tr/signin" target="_blank">
                      <Stack direction="horizontal">
                          <Image className="mx-auto" src={says} />
                      </Stack>
                    </a>
                </Dropdown.Menu>
              </Dropdown>
              <Link to="/" className="_logo_wrapper">
                <Image src={logo}  />
              </Link>
            </Stack>
          </Col>
          <Col className="d-flex justify-content-center align-items-center position-relative">
            <InputGroup className="mb-3">
              <FormControl
                placeholder={ selectedProject.name+"'nde Ara"}
                className="searchInput"
                size="md"
                onKeyDown={handleKeyDown}
                onChange={(e) => onChangeSearch(e.target.value)}
                value={searchText}
              />
              <Button variant="danger" className="text-white px-3" onClick={()=>onChangeSearch(searchText)}>
                <span dangerouslySetInnerHTML={{ __html: Icons.search }} className="searchIcon"/>
                Ara
              </Button>
            </InputGroup>

            {isShow && (
                <div  ref={resultsRef}
                  className="position-absolute w-100"
                  style={{ top: "100%", zIndex: "10", backgroundColor: 'white', borderRadius: '5px' }}
                >
                  <Tabs defaultActiveKey={titleData.length >0 ? "tab1" : contentData.length > 0 ? "tab2" : "tab3"} fill>
                    <Tab eventKey="tab1" title="Başlık" disabled={titleData.length===0}>
                      <ListGroup>
                        {
                          titleData.map((item,index)=>    <ListGroup.Item action key={"title"+index} onClick={()=>{navigate(getItemPath(item)); clearData(); }}>
                          <strong className="d-block">
                            {item.title}
                          </strong>
                          <p>{item.definition}</p>
                        </ListGroup.Item>)
                        } 
   
                      </ListGroup>
                    </Tab>
                    <Tab eventKey="tab2" title="İçerik">
                      
                    <ListGroup>
                    {
                          contentData.map((item,index)=>    <ListGroup.Item action key={"title"+index}  onClick={()=>{navigate(getItemPath(item)); clearData(); }}>
                          <strong className="d-block">
                            {item.title}
                          </strong>
                          <p>{item.definition}</p>
                        </ListGroup.Item>)
                        }

                      </ListGroup>
                    </Tab>
                    <Tab eventKey="tab3" title="Yazar">
                      
                    <ListGroup>
                    {
                          writerData.map((item,index)=>    <ListGroup.Item action key={"title"+index} onClick={()=>{navigate(`/yazar-detay/${item.userId}/${item.writerName}`); clearData(); }} >
                          <strong className="d-block">
                            {item.writerName}
                          </strong>
                        </ListGroup.Item>)
                        }

                      </ListGroup>
                    </Tab>
                  </Tabs>
                </div>
            )}
            
          </Col>
          <Col className="justify-content-center justify-content-md-end d-none d-md-flex" md={4}>
          <a href={"https://www.ayk.gov.tr/"} target="_blank" rel="noreferrer" style={{marginRight:10}}>
              <Image
                src={yk}
                width={80}
                alt={selectedProject.instLongName}
              />
            </a>
            <a href={selectedProject.instUrl} target="_blank" rel="noreferrer">
              <Image
                src={atam}
                width={80}
                alt={selectedProject.instLongName}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
