import React from 'react';

const AtaturkAnsiklopedisi = () => {
  return (
    <div>
      <p style={{ textAlign: 'center', fontSize: '12pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold' }}>
        ATATÜRK ANSİKLOPEDİSİ MADDE YAZIM İLKELERİ, TEKLİFİ, SÖZLÜK VE ANSİKLOPEDİ YÖNETİM SİSTEMİ (SAYS) KULLANIM VE MADDE YÜKLEME YÖNERGESİ
      </p>

      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        Yayın hayatına 29 Ekim 2020 yılında başlayan Atatürk Ansiklopedisi, çevrimiçi ortamda{' '}
        <a href="https://ataturkansiklopedisi.gov.tr/" style={{ color: 'blue', textDecoration: 'underline' }}>
          https://ataturkansiklopedisi.gov.tr/
        </a>{' '}
        adresi üzerinden yayımlanır.
      </p>

      <h3 style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        1. Ansiklopedinin Yayın Amacı
      </h3>
      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        Mustafa Kemal Atatürk’ün doğumundan başlayarak <span style={{ color: 'black' }}>1960</span>
        ’a kadar olan süreç içerisinde Türkiye tarihini ilgilendiren olaylar, olgular, kurumlar ve şahsiyetler hakkında
        doğru bilgiyi kamuoyu ile paylaşmaktır.
      </p>

      <h3 style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        2. Ansiklopedinin Konusu
      </h3>
      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        Birinci aşamada Mustafa Kemal Atatürk’ün doğumundan vefatına kadar devam eden süre içinde Atatürk’ün yaşadığı
        mekânlar (beşerî ve fiziki), başlattığı, yönettiği ya da yönlendirdiği ve Türkiye’nin geleceğinin
        biçimlenmesinde ve toplumun yapılanmasında etkili olan olay ve olgular, Atatürk’ün gerçekleştirdiği eylemlerle
        doğrudan ilgili olan şahsiyetler hakkındaki yazıları içermektedir. İkinci aşamada ise Mustafa Kemal Atatürk’ün
        vefatından 1960’a kadar olan süreç içerisinde Türkiye tarihini ilgilendiren olaylar, olgular, kurumlar ve
        şahsiyetlere dair çalışmaları barındırmaktadır.
      </p>

      <h3 style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        3. Ansiklopedi İçeriğinde Yer Alacak Çalışmalar
      </h3>
      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        Ansiklopedi için belirlenen zaman aralığına uygun, birincil kaynaklara dayandırılarak hazırlanan yazılar
        olmalıdır.
      </p>
      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: 'black' }}>
        Gönderilecek olan metinler alanında çalışma sahibi olan yazarlar tarafından hazırlanmalı ve ansiklopedi madde
        formatına uygun olarak daha önce başka bir yayın organında yayımlanmamış ya da ansiklopedi formatına göre
        yeniden yazılmış olmalıdır.
      </p>

      <h3 style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        4. Maddelerin Değerlendirilmesi
      </h3>
      <p style={{ textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        Gelen metinler, Madde Takip Görevlisi (MTG) tarafından yazım kurallarına uygunluğu bakımından şekil olarak
        kontrol edildikten sonra yazım kurallarına herhangi bir aykırılık yoksa Atatürk Ansiklopedisi Komisyon
        (Çalışma Grubu) gündemine eklenir. Çalışma Komisyonu tarafından yayımlanması uygun görülmeyen yazıların
        yazarlarına herhangi bir telif ücreti ödemesi yapılmaz.
      </p>
      <p>
        <span style={{ fontSize: '12pt', backgroundColor: 'white', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Maddenin genel olarak aşağıda belirtilen düzene göre sisteme yüklenmesine özen gösterilmelidir:
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          1- Başlık (Yazılan madde bir kişinin anlatımı ise doğum ve vefat tarihleri başlıktan sonra verilmelidir. Madde bir olay ise yine başlıktan sonra meydana geldiği tarih yazılmalıdır.)
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          2- Tanım
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          3- Anahtar Kelimeler (En az 4, en çok 8 anahtar kelime alfabetik sıra ile verilmelidir.)
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          4- Özet
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          5- Ana Metin
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          6- Kaynakça
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          7- Madde Görseli Yüklemesi
        </span>
      </p>

      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          8- Madde Word Dosyasının Yüklemesi
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.2. Özet
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          En fazla 200 kelime olacak şekilde Türkçe olarak ayrı yazılmalıdır. Öz, maddenin ana fikrini ve maddeyi tanıtacak nitelikte olmalıdır.
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.3. Ana Metin
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Madde ana metni, Sözlük ve Ansiklopedi Yönetim Sisteminde (SAYS) “Madde Metni” kısmına bir kompozisyon şeklinde, kolay okunabilir özellikte ansiklopedi madde formatında girilmelidir...
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.4. Bölüm Başlıkları
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Atatürk Ansiklopedisi madde metininde ara/alt başlık vb. kullanılmamalıdır.
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.5. Alıntılar
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Metinde gereğinden fazla alıntılara yer verilmemesi gerektiği gibi alıntılar tırnak içinde veya italik verilmelidir...
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.6. Atıf Gösterme
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Metin içerisinde dipnot kullanılmamalıdır. Maddenin yazımında kullanılan kaynaklar kaynakçada belirtilmelidir.
        </span>
      </p>

      <h3 style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
        6.7. Kaynakça Yazımı
      </h3>
      <p>
        <span style={{ fontSize: '12pt', fontFamily: '"Times New Roman", serif', color: '#333333' }}>
          Maddede kullanılan kaynaklar SAYS’ta “Kaynakça” kısmına girilmelidir.
        </span>
      </p>
    </div>
  );
};

export default AtaturkAnsiklopedisi;
