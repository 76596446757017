import bosPng from '../assets/bos.png'
import config from '../config';
export const titleUrlDeleteBaskSpace = (title) => {
    return title !== undefined ?  title.replace(/ /g , "_") : "";
  }
  export const getItemPrimaryImage = (img) => {
    return img !== undefined && img !== "" ?  img : bosPng;
  }

export const getDetailLink = (item) => {
    const title =item?.title !== undefined ?  item.title.replace(/ /g , "-") : "";
    return item !== undefined ?  `/detay/${item?.id}/${title}` : "#";
  }

  export const getDateLocale = (date) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
    return new Date(date).toLocaleDateString("tr-TR", options);
}

export const getItemUrl = (item)=>{

    return `${config.appUrl}/detay/${item.id}/${encodeURIComponent(titleUrlDeleteBaskSpace(item.title))}`
}
export const getItemPath = (item)=>{

  return `/detay/${item.id}/${encodeURIComponent(titleUrlDeleteBaskSpace(item.title))}`
}

export function getFullName(user){
  return user.title !== "" ? user.title +' '+ user.name +' '+ user.surname : user.name +' '+ user.surname ;
}  

export function getNameSurname(user){
  return  user.name +' '+ user.surname ;
}  
export function capitalizeTurkish(sentence) {
  if (!sentence) return "";

  return sentence
    .split(" ") // Cümleyi boşluklara göre ayır
    .map(word => {
      if (!word) return ""; // Boş kelimeleri atla
      
      // Eğer parantezle başlıyorsa veya parantezden sonra bir harf varsa
      if (word.startsWith("(") || word.startsWith("-")) {
        const firstLetterIndex = word.search(/[a-zA-ZçğıöşüÇĞİÖŞÜ]/); // İlk harfi bul
        if (firstLetterIndex !== -1) {
          const firstLetter = word[firstLetterIndex].toLocaleUpperCase("tr-TR");
          const rest = word.slice(firstLetterIndex + 1).toLocaleLowerCase("tr-TR");
          return word.slice(0, firstLetterIndex) + firstLetter + rest;
        }
      }

      // Diğer kelimeler için
      const firstLetter = word[0].toLocaleUpperCase("tr-TR"); // İlk harfi büyüt
      const rest = word.slice(1).toLocaleLowerCase("tr-TR");  // Kalan harfleri küçült
      return firstLetter + rest;
    })
    .join(" "); // Kelimeleri tekrar birleştir
}


export function getFullNameCapitalize(user){
  return capitalizeTurkish(user.name +' '+ user.surname) ;
}  

